import React from "react";
import { MapConfigItem } from "./TenantMapContext";
import LinkCard from "./LinkCard";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  SxProps,
  Button,
} from "@mui/material";
import history from "../redux/history";
import { useAuth0 } from "@auth0/auth0-react";
import useHandleAuthAction from "../useHandleAuthAction"; // Adjust the import path as needed

export interface MyMapsListProps {
  configs: MapConfigItem[];
  style?: SxProps;
}

const MyMapsList: React.FC<MyMapsListProps> = ({ configs, style }) => {
  const { isAuthenticated } = useAuth0();
  const handleAuthAction = useHandleAuthAction();

  const navigateToMap = (tenant: string, map: string) => {
    history.push(`/${tenant}/${map}`);
  };

  return (
    <Card sx={{ ...style, maxWidth: 345, mx: "auto", mt: 5, overflow: "auto" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          textAlign="center"
        >
          My PioneerMaps
        </Typography>
        {isAuthenticated ? (
          configs.length > 0 ? (
            <Stack direction="column" spacing={2}>
              {configs.map((config, index) => (
                <LinkCard
                  key={config.tenant + config.map}
                  title={config.creator + ": " + config.title}
                  onClick={() => navigateToMap(config.tenant, config.map)}
                />
              ))}
            </Stack>
          ) : (
            <Typography textAlign="center" color="textSecondary">
              No maps found.
            </Typography>
          )
        ) : (
          <>
            <Typography textAlign="center">
              You are not logged in. Please log in to view your maps.
            </Typography>
            <Stack direction="row" justifyContent="center" mt={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleAuthAction({ action: "login" })}
              >
                Log in
              </Button>
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MyMapsList;
