import { useAuth0 } from "@auth0/auth0-react";
import { sendEvent } from "./analytics/events"; // Adjust the import path as needed

interface LoginOptions {
  action: "login" | "signup";
  tenantName?: string;
  mapName?: string;
  options?: any;
}

const useHandleAuthAction = () => {
  const { loginWithRedirect } = useAuth0();

  const handleAuthAction = async ({
    action,
    tenantName,
    mapName,
    options = {},
  }: LoginOptions) => {
    try {
      // Store the desired return path in localStorage
      localStorage.setItem("postLoginRedirectPath", window.location.pathname);

      options.authorizationParams = options.authorizationParams || {};
      // set some metadata to make sense out of the logs
      // see Action -> Library - Custom in the auth0 dashboard
      options.authorizationParams.tenantName = tenantName;
      options.authorizationParams.mapName = mapName;
      options.authorizationParams.env = process.env.REACT_APP_ENV;
      options.authorizationParams.fromPath = window.location.pathname;

      if (action === "signup") {
        options.authorizationParams.screen_hint = "signup";
        sendEvent("User", "Click Sign up", `${tenantName} - ${mapName}`);
      } else {
        sendEvent("User", "Click Login", `${tenantName} - ${mapName}`);
      }

      await loginWithRedirect(options);
    } catch (err) {
      console.error(`Error during ${action}`, err);
      // Optionally handle the error, show an alert if needed
      // showAlert("Oops! Something went wrong.", "error", 5); // Uncomment if showAlert is available
    }
  };

  return handleAuthAction;
};

export default useHandleAuthAction;
