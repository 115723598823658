import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "./Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export interface InfoProps {
  infoOpen: boolean;
  setInfoOpen: Function;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export const Info: React.FC<InfoProps> = ({ infoOpen, setInfoOpen }) => {
  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <Modal
      open={infoOpen}
      onClose={handleInfoClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Box textAlign="left">
            <Typography sx={{ fontWeight: "bold" }}>
              PioneerMaps: Social Media Maps
            </Typography>
            <Typography sx={{ pt: 2 }}>
              Discover the world through the eyes of your favorite travel
              content creators with PioneerMaps.
            </Typography>
            <Typography sx={{ pt: 2 }}>
              Our platform transforms the diverse social media adventures of
              these creators into first-class, interactive maps, offering you
              unique and personalized travel guides.
            </Typography>
            <Typography sx={{ pt: 2 }}>
              PioneerMaps is your gateway to experiencing tailored adventures,
              inspired by the travels you love to follow.
            </Typography>
          </Box>
          <Box textAlign="center">
            <Button
              sx={{ mr: 1, mt: 2 }}
              onClick={() => {
                handleInfoClose();
              }}
              icon={<CloseIcon />}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
