import React, { CSSProperties } from "react"; // Import CSSProperties for typing the style object
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export interface PurchaseErrorRouteParams {
  map_name: string;
  tenant_name: string;
  user_id: string;
}

export interface PurchaseErrorProps {
  style?: CSSProperties;
}

const PurchaseError: React.FC<PurchaseErrorProps> = ({ style }) => {
  const { tenant_name, map_name, user_id } =
    useParams<PurchaseErrorRouteParams>();
  const timestamp = new Date().toISOString();

  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", marginBottom: "50px", ...style }} // Spread the style prop to override or add new styles
    >
      <ErrorOutlineIcon
        style={{ fontSize: 60, color: "red", marginBottom: "20px" }}
      />
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        style={{ marginBottom: "32px" }}
      >
        Purchase Failed
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "16px" }}>
        Unfortunately, your purchase could not be completed. Please try again
        later.
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "20px" }}>
        If you need assistance, please contact us at{" "}
        <a
          href="mailto:support@pioneermaps.com"
          style={{ textDecoration: "none" }}
        >
          support@pioneermaps.com
        </a>
        .
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "20px" }}>
        Please mention the following information when you contact support:
        <Typography
          variant="body2"
          component="span"
          color="text.secondary"
          style={{
            display: "block",
            overflowWrap: "break-word",
            maxWidth: "100%",
            marginBottom: "24px",
          }}
        >
          ID: {tenant_name}/{map_name}, User: {user_id}, Time: {timestamp}
        </Typography>
      </Typography>
      <Button
        variant="contained"
        startIcon={<MailOutlineIcon />}
        href={`mailto:support@pioneermaps.com?subject=Purchase Error Assistance&body=I encountered an error during purchase.%0A%0AMap Name: ${tenant_name}/${map_name}%0AUser: ${user_id}%0ATime: ${timestamp}%0A%0APlease assist.`}
        style={{
          backgroundColor: "#2A3235",
          color: "#FFFFFF",
          marginTop: "32px",
          padding: "10px 20px",
        }}
      >
        Contact Support
      </Button>
    </Container>
  );
};

export default PurchaseError;
