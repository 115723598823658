import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const LinkCardContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "5px",
  borderRadius: "15px",
  backgroundColor: "#2A3235",
  color: "white",
  height: "40px",
});

const imageSize = "40px";

const Image = styled(Avatar)({
  width: imageSize,
  height: imageSize,
  borderRadius: "10px",
  marginRight: "10px",
});

export interface LinkCardProps {
  imageSrc?: string;
  title: string;
  onClick?: () => void;
}

const LinkCard: React.FC<LinkCardProps> = ({ imageSrc, title, onClick }) => {
  return (
    <LinkCardContainer onClick={onClick}>
      {imageSrc ? <Image alt="Link image" src={imageSrc} /> : null}
      <Typography
        variant="button"
        sx={{
          textAlign: "center",
          marginRight: imageSrc ? imageSize : 0,
          textTransform: "none",
          flexGrow: 1,
        }}
      >
        {title}
      </Typography>
    </LinkCardContainer>
  );
};

export default LinkCard;
