import React from "react";
import "./InstaPostThumbnail.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export interface InstaPostThumbnailProp {
  thumbnailURL?: string;
  instagramPostID?: string;
  userPhoto?: string;
  userPost?: string;
  embedPost?: boolean;
}

export const InstaPostThumbnail: React.FC<InstaPostThumbnailProp> = ({
  thumbnailURL,
  instagramPostID,
  userPhoto,
  userPost,
  embedPost = false,
}) => {
  const postURL = "https://www.instagram.com/p/" + instagramPostID;

  return (
    <Box sx={{ flexDirection: "column", justifyContent: "center" }}>
      {embedPost ? (
        <div className="embed-container">
          <iframe
            src={`https://www.instagram.com/p/${instagramPostID}/embed`}
          ></iframe>
        </div>
      ) : (
        <>
          <a href={postURL}>
            <img
              className={"content"}
              src={thumbnailURL}
              alt={"Thumbnail of Instagram post with id " + instagramPostID}
            />
          </a>
          {userPost && (
            <Typography align={"center"}>
              Creator:{" "}
              <Link href={postURL} underline="hover">
                {"@" + userPost}{" "}
              </Link>
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
