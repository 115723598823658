import React, { ReactElement } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export interface LabelProps extends MuiButtonProps {
  children?: React.ReactNode;
  borderRadius?: number;
  icon?: ReactElement;
  iconSize?: number;
  active?: boolean;
  width?: number;
}

export const Label: React.FC<LabelProps> = ({
  children,
  borderRadius = 20,
  icon,
  iconSize = 24,
  active = false,
  disabled = false,
  width = 110,
  onClick,
  ...rest
}) => {
  const bgColor = active ? "#2A3235" : "white";
  const textColor = active ? "white" : "#2A3235";

  const iconElement = icon
    ? React.cloneElement(icon, {
        style: {
          ...icon.props.style,
          color: textColor,
          fontSize: iconSize,
          width: iconSize,
          height: iconSize,
        },
      })
    : null;

  return (
    <MuiButton
      variant="contained"
      startIcon={iconElement}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderRadius,
        opacity: disabled ? 0.5 : 1,
        width,
      }}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <Typography
        variant="button"
        style={{ textTransform: "none", color: textColor }}
      >
        {children}
      </Typography>
    </MuiButton>
  );
};
