import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

const initialState = {};

function dataReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    data: dataReducer,
  });

export default createRootReducer;
