import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { useAlert } from "./AlertContext";

export const AlertPopup: React.FC = () => {
  const { alertState, closeAlert } = useAlert();

  useEffect(() => {
    if (alertState.duration > 0 && alertState.isOpen) {
      const timer = setTimeout(() => {
        closeAlert();
      }, alertState.duration * 1000);

      return () => clearTimeout(timer);
    }
  }, [alertState, closeAlert]);

  return (
    <Collapse in={alertState.isOpen}>
      <Alert
        severity={alertState.severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeAlert}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {alertState.message}
      </Alert>
    </Collapse>
  );
};
