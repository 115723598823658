import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from "react";

export interface TenantConfig {
  [key: string]: {
    [key: string]: MapConfigItem;
  };
}

export interface MapConfigItem {
  tenant: string;
  map: string;
  site: string;
  creator: string;
  price?: string;
  discountedPrice?: string;
  priceID: string;
  title: string;
  description: string;
  imagePath: string;
}

interface TenantMapContextValue {
  tenantConfig: TenantConfig[string][string] | undefined;
  updateTenantMap: (config: TenantConfig[string][string]) => void;
}

export const TenantMapContext = createContext<TenantMapContextValue | null>(
  null
);

export const useTenantMap = () =>
  useContext(TenantMapContext) as TenantMapContextValue;

interface TenantMapProviderProps {
  children: ReactNode;
}

export const TenantMapProvider: React.FC<TenantMapProviderProps> = ({
  children,
}) => {
  const [currentConfig, setCurrentConfig] = useState<
    TenantConfig[string][string] | undefined
  >(undefined);

  const updateTenantMap = useCallback(
    (config: TenantConfig[string][string]) => {
      setCurrentConfig(config);
    },
    []
  );

  return (
    <TenantMapContext.Provider
      value={{ tenantConfig: currentConfig, updateTenantMap }}
    >
      {children}
    </TenantMapContext.Provider>
  );
};
