import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "./Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout"; // Import for logout icon
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "./AlertContext";

export interface ProfileProps {
  profileOpen: boolean;
  setProfileOpen: Function;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export const Profile: React.FC<ProfileProps> = ({
  profileOpen,
  setProfileOpen,
}) => {
  const { showAlert } = useAlert();
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const handleLogout = () => {
    localStorage.setItem("postLogoutRedirectPath", window.location.pathname);
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: window.location.origin + "/auth-callback",
      },
    }).catch((err) => {
      console.error("Error during logout", err);
      showAlert("Oops! Something went wrong.", "error", 5);
    });
    setProfileOpen(false); // Close the profile popup after logging out
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <Modal
        open={profileOpen}
        onClose={handleProfileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box textAlign="left">
            <Typography sx={{ pt: 2 }}>Name: {user.name}</Typography>
            <Typography sx={{ pt: 2 }}>Email: {user.email}</Typography>
          </Box>
          <Box
            textAlign="center"
            sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}
          >
            <Button onClick={handleLogout} icon={<LogoutIcon />}>
              Logout
            </Button>
            <Button onClick={handleProfileClose} icon={<CloseIcon />}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  );
};
