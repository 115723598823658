import React, { Component } from "react";
import ReactPlayer from "react-player";
import { getVideoURL } from "./helper";
import "./Video.css";

export interface IVideoProps {
  startTime?: number;
  platform?: string;
  platformVideoID?: string;
  drawerOpen?: boolean;
  updatePlaytime?: (playedSeconds: number) => void;
}

interface IVideoState {
  pip: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  playedSeconds: number;
  seeking: boolean;
  firstPlayAfterMount: boolean;
  seekingCalled: boolean;
}

const initialVideoState: IVideoState = {
  // eslint-disable-next-line no-invalid-this
  pip: false,
  playing: false,
  controls: true,
  light: false,
  volume: 0.8,
  muted: true,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  playedSeconds: 0,
  seeking: false,
  firstPlayAfterMount: false,
  seekingCalled: false,
};

// inspiration from
// https://github.com/CookPete/react-player/blob/master/src/demo/App.js
// https://cookpete.com/react-player/
export class Video extends Component<IVideoProps, IVideoState> {
  static defaultProps = {
    startTime: 0,
  };
  player: any;

  constructor(props: IVideoProps) {
    super(props);
    this.state = initialVideoState;
    this.player = React.createRef();
  }

  componentDidMount() {
    console.log("componentDidMount");
    // sometimes the props are not defined yet when this is called
    // but sometimes they are and then we call bootstrap
    if (this.props.startTime !== 0) {
      switch (this.props.platform) {
        case "youtube":
          this.bootstrapYouTube();
          break;
        case "vimeo":
          this.bootstrapVimeo();
          break;
      }
    }
  }

  componentDidUpdate(prevProps: IVideoProps, prevState: IVideoState) {
    console.log("componentDidUpdate");
    // sometimes props are only available in componentDidUpdate
    // make sure we call the bootstrap functions only once
    switch (this.props.platform) {
      case "youtube":
        if (this.props.startTime !== prevProps.startTime) {
          this.bootstrapYouTube();
        }
        break;
      case "vimeo":
        // detect new video or new scene
        // TODO only videoID and startTime is uniquely identifying a new scen
        if (this.props.startTime !== prevProps.startTime) {
          this.setState({ seekingCalled: false });
          this.bootstrapVimeo();
          break;
        } else {
          // no new scene but seek was not yet called for some reason
          // needed for vimeo videos on iPhone SE safari
          if (!this.state.seekingCalled) {
            this.player.seekTo(this.props.startTime);
          }
        }
    }

    // Make sure video is stopped when drawer is closed
    if (prevProps.drawerOpen && !this.props.drawerOpen) {
      this.setState({ playing: false });
    }
  }

  bootstrapYouTube = () => {
    console.log("bootstrapYouTube");
    // rely on timeStart in URL with youtube instead of unsupported seekTo
    // play video a few ms at first to not
    // show the thumbnail of scene at timeStart
    if (this.props.platform === "youtube" && this.props.startTime !== 0) {
      this.setState({ firstPlayAfterMount: true, playing: true, muted: true });
    }
  };

  bootstrapVimeo = () => {
    console.log("bootstrapVimeo");
    this.player.seekTo(this.props.startTime);
  };

  handlePlay = () => {
    console.log("onPlay");
    // stop vimeo bootstrap
    if (this.state.seeking) {
      this.setState({ seeking: false, playing: true, muted: true });
      // stop youtube bootstrap
    } else if (this.state.firstPlayAfterMount) {
      this.setState({ firstPlayAfterMount: false, playing: true, muted: true });
    } else {
      this.setState({ playing: true, muted: true });
    }
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
  };

  handleSeekChange = (e: any) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleProgress = (state: any) => {
    console.log("handleProgress");
    // Don't update playtime when bootstrap
    if (!this.state.firstPlayAfterMount) {
      this.setState(state);
      this.props.updatePlaytime(state.playedSeconds);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration: number) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleOnSeek = (e: any) => {
    // onSeek is not called with youtube
    // https://github.com/CookPete/react-player/issues/356
    console.log("onSeek", e);
    this.setState({ seeking: true, seekingCalled: true, playing: true });
  };

  handleOnStart = () => {
    console.log("onStart");
  };

  handleOnReady = () => {
    console.log("onReady");
  };

  ref = (player: any) => {
    this.player = player;
  };

  render() {
    return (
      <div className="react-player">
        {/*@ts-ignore*/}
        <ReactPlayer
          className="react-player"
          ref={this.ref}
          width="100%"
          height="100%"
          url={getVideoURL(
            this.props.platform,
            this.props.platformVideoID,
            this.props.startTime
          )}
          pip={this.state.pip}
          playing={this.state.playing}
          controls={this.state.controls}
          light={this.state.light}
          loop={this.state.loop}
          playbackRate={this.state.playbackRate}
          volume={this.state.volume}
          muted={this.state.muted}
          onReady={this.handleOnReady}
          onStart={this.handleOnStart}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log("onBuffer")}
          onSeek={(e) => this.handleOnSeek(e)}
          onEnded={this.handleEnded}
          onError={(e) => console.log("onError", e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          progressInterval={100}
        />
      </div>
    );
  }
}
