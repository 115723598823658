import React, { ReactElement } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import DirectionsIcon from "@mui/icons-material/Directions";
import Typography from "@mui/material/Typography";

export interface ButtonProps extends MuiButtonProps {
  children?: React.ReactNode;
  borderRadius?: number;
  bgColor?: string;
  icon?: ReactElement;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  borderRadius = 20,
  bgColor = "#2A3235",
  icon = <DirectionsIcon />,
  disabled = false,
  ...rest
}) => {
  return (
    <MuiButton
      variant="contained"
      color="secondary"
      startIcon={icon}
      style={{
        backgroundColor: bgColor,
        borderRadius,
        opacity: disabled ? 0.5 : 1,
      }}
      {...rest}
    >
      <Typography variant="button" style={{ textTransform: "none" }}>
        {children}
      </Typography>
    </MuiButton>
  );
};
