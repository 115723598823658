import React from "react";
import { TikTokEmbed as RsmTikTokEmbed } from "react-social-media-embed";

export interface TikTokEmbedProps {
  url: string;
}

export const TikTokEmbed: React.FC<TikTokEmbedProps> = ({ url }) => {
  console.log("TikTokEmbedProps", url);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <RsmTikTokEmbed url={url} width={325} />
    </div>
  );
};
