import * as React from "react";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  GetYourGuideWidget,
  GetYourGuideWidgetProps,
} from "./GetYourGuideWidget";
import { Button } from "./Button";
import { deviceType } from "detect-it";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { SplitWords } from "../helperts";
import ExternalLinkIcon from "@mui/icons-material/OpenInNew";
import DirectionsIcon from "@mui/icons-material/Directions";
import ShareIcon from "@mui/icons-material/Share";
import Paper from "@mui/material/Paper";
import { sendEvent } from "../analytics/events";
import { SharePopup } from "./SharePopup";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 6,
  left: "calc(50% - 15px)",
}));

const useStyles = makeStyles({
  containerBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    marginBottom: "8px",
    marginLeft: "16px",
    marginRight: "16px",
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  childBoxLeft: {
    paddingLeft: "0px",
    margingLeft: "0px",
  },
  childBoxCenter: {
    marginLeft: "25px",
    marginRight: "25px",
  },
  childBoxRight: {
    paddingRight: "0px",
    marginRight: "0px",
  },
  button: {
    width: "100%",
  },
});

export interface SwipeableEdgeDrawerProps {
  media: ReactNode;
  drawerBleeding: number;
  drawerOpen: boolean;
  setDrawerOpen: Function;
  gygWidget: GetYourGuideWidgetProps;
  displayActivity: boolean;
  setDisplayActivity: Function;
  localInfoURL: string | undefined;
  address: string | undefined;
  categories: string[];
  destination: string;
  location: {
    latitude: number;
    longitude: number;
  };
  setPlayedSeconds: Function;
  text: string;
}

export const SwipeableEdgeDrawer: React.FC<SwipeableEdgeDrawerProps> = ({
  media,
  drawerBleeding,
  drawerOpen,
  setDrawerOpen,
  gygWidget,
  displayActivity,
  setDisplayActivity,
  localInfoURL,
  address,
  categories,
  destination,
  location,
  setPlayedSeconds,
  text,
}) => {
  const [sharePopupOpen, setSharePopupOpen] = React.useState<boolean>(false);

  const handleShareClick = () => {
    sendEvent("User", "edge drawer share click");
    setSharePopupOpen(true);
  };

  function handleDrawerClose() {
    if (drawerOpen) {
      setDrawerOpen(!drawerOpen);
    }
  }

  function handleDrawerOpen() {
    if (!drawerOpen) {
      setDrawerOpen(!drawerOpen);
    }
  }

  function handleLocalInfoClick(e: React.MouseEvent<HTMLElement>) {
    sendEvent("User", "edge drawer local info click");
    if (localInfoURL) {
      e.preventDefault();
      window.open(localInfoURL, "_blank");
    }
  }

  function handleDirectionsClick(e: React.MouseEvent<HTMLElement>) {
    sendEvent("User", "edge drawer direction click");
    if (location && location.longitude && location.latitude) {
      e.preventDefault();
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${location.latitude}%2C${location.longitude}`,
        "_blank"
      );
    }
  }

  const classes = useStyles();
  const splitText = SplitWords(text, " ", 15);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={drawerOpen}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
      onClick={handleDrawerOpen}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {address && destination && (
        <Helmet>
          <title>{address + ", " + destination}</title>
          <meta
            name="description"
            content={
              categories.join(", ") + " in " + address + ", " + destination
            }
          />
          <meta property="og:title" content={address + ", " + destination} />
          <meta
            property="og:description"
            content={
              categories.join(", ") + " in " + address + ", " + destination
            }
          />
        </Helmet>
      )}
      <StyledBox
        sx={{
          position: "absolute",
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: "visible",
          right: 0,
          left: 0,
          ...(deviceType === "mouseOnly" && { pointerEvents: "all" }),
        }}
      >
        <Puller />
        <Box className={classes.containerBox}>
          <Box className={classes.childBoxLeft}>
            <Button
              icon={null}
              endIcon={<ExternalLinkIcon />}
              onClick={handleLocalInfoClick}
              size="small"
              disabled={!localInfoURL}
            >
              {"Info"}
            </Button>
          </Box>
          <Box className={classes.childBoxCenter}>
            <Button
              icon={<ShareIcon />}
              onClick={handleShareClick}
              size="small"
            >
              {"Share"}
            </Button>
          </Box>
          <Box className={classes.childBoxRight}>
            <Button
              icon={null}
              endIcon={<ExternalLinkIcon />}
              onClick={handleDirectionsClick}
              size="small"
            >
              {"Directions"}
            </Button>
          </Box>
        </Box>
      </StyledBox>

      <StyledBox
        sx={{
          pb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box sx={{ textAlign: "center", pt: 1 }}>
          <Box>
            {address && (
              <Typography sx={{ fontWeight: "bold" }} align={"center"}>
                {address}
              </Typography>
            )}
            {splitText.length >= 1 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    px: 2,
                    pb: 1,
                    maxWidth: "800px",
                  }}
                >
                  <Typography align={"left"}>
                    {splitText[0] + " ..."}
                  </Typography>
                </Box>
              </Box>
            )}
            <StyledBox sx={{ px: 0 }}>{media}</StyledBox>
            {splitText.length >= 2 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    px: 2,
                    mt: 1,
                    maxWidth: "800px",
                  }}
                >
                  <Typography align={"left"}>{splitText[1]}</Typography>
                </Box>
              </Box>
            )}
          </Box>
          {drawerOpen && (
            <Box justifyContent="center" alignItems="center" sx={{ m: 2 }}>
              <Typography sx={{ fontWeight: "bold" }} align={"center"}>
                Local Activities
              </Typography>
              <Box>
                <Paper
                  elevation={16}
                  sx={{ mt: 1 }}
                  style={{ borderRadius: "10px" }}
                >
                  <GetYourGuideWidget numberOfItems={1} {...gygWidget} />
                </Paper>
              </Box>
            </Box>
          )}
        </Box>
      </StyledBox>
      <SharePopup
        label={"Share URL"}
        value={window.location.href}
        setSharePopupOpen={setSharePopupOpen}
        sharePopupOpen={sharePopupOpen}
      />
    </SwipeableDrawer>
  );
};
