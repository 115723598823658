import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/store";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./redux/history";
import { ApiServiceProvider } from "@visitswitzerland/common";
import { UserProvider } from "./components/UserContext";
import { AlertProvider } from "./components/AlertContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

// @ts-ignore
window.$defaultMapCenter = {
  lat: 0,
  lng: 0,
  zoom: 0,
}; // global variable

// Multiple products (previously known as trackers)
ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA4_ID,
  },
]);

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("./mocks/browser");
//   worker.start();
// }

const store = configureStore();

// It creates the mock server only in development mode
// if (process.env.NODE_ENV === 'development') {
//   createServer();
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin + "/auth-callback",
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email offline_access",
          }}
          // Refresh Token Rotation, needed to fix login because "Prevent cross-site tracking" is enabled by default on Mac Safari and iPhone
          // https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          <ApiServiceProvider>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <App history={history} />
              </ThemeProvider>
            </UserProvider>
          </ApiServiceProvider>
        </Auth0Provider>
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
