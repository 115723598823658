import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Button } from "./Button";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

export interface ContactFormProps {
  contactFormOpen: boolean;
  setContactFormOpen: Function;
  contactFormURL: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export const ContactForm: React.FC<ContactFormProps> = ({
  contactFormOpen,
  setContactFormOpen,
  contactFormURL,
}) => {
  const [status, setStatus] = useState<string>("");
  const handleContactFormClose = () => {
    setStatus("");
    setContactFormOpen(false);
  };

  function submitForm(ev: any) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  }

  return (
    <Modal
      open={contactFormOpen}
      onClose={handleContactFormClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* @ts-ignore*/}
      <Box sx={style}>
        <form onSubmit={submitForm} action={contactFormURL} method="POST">
          <FormControl fullWidth component="fieldset" variant="standard">
            <FormLabel>Contact us:</FormLabel>
            <FormGroup>
              <TextField
                label="Email"
                name="email"
                size="small"
                margin="dense"
              />
              <TextField
                label="Message"
                name="message"
                multiline
                rows={4}
                size="small"
                margin="dense"
              />
            </FormGroup>
          </FormControl>
          <Box textAlign="center">
            <Button
              sx={{ mr: 1, mt: 2 }}
              onClick={() => {
                handleContactFormClose();
              }}
              icon={<CloseIcon />}
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ ml: 1, mt: 2 }}
              icon={<SendIcon />}
            >
              Submit
            </Button>
          </Box>
        </form>
        <Box style={{ paddingTop: 12 }}>
          {status === "SUCCESS" && <p>Thanks!</p>}
          {status === "ERROR" && <p>Oops! There was an error.</p>}
        </Box>
      </Box>
    </Modal>
  );
};
