import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Button } from "./Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { sendEvent } from "../analytics/events";

export interface SharePopupProps {
  sharePopupOpen: boolean;
  setSharePopupOpen: Function;
  value: string;
  label: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export const SharePopup: React.FC<SharePopupProps> = ({
  sharePopupOpen,
  setSharePopupOpen,
  value,
  label,
}) => {
  const handleSharePopupClose = () => {
    setSharePopupOpen(false);
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    sendEvent("User", "share popup copy to clipboard click");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Modal
      open={sharePopupOpen}
      onClose={handleSharePopupClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <TextField
          label={label}
          value={value}
          size="small"
          margin="dense"
          InputProps={{
            endAdornment: (
              <CopyToClipboard text={value} onCopy={handleCopyToClipboard}>
                <IconButton disabled={isCopied}>
                  <FileCopyIcon />
                </IconButton>
              </CopyToClipboard>
            ),
          }}
          disabled={isCopied}
          sx={{ width: "100%" }}
        />

        <Box textAlign="center">
          <Button
            sx={{ mr: 1, mt: 2 }}
            onClick={() => {
              handleSharePopupClose();
            }}
            icon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
