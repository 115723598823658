import React, { CSSProperties } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export interface SuccessPurchaseRouteParams {
  tenant_name: string;
  map_name: string;
  user_id: string;
}

export interface PurchaseSuccessProps {
  style?: CSSProperties;
}

const SuccessPurchase: React.FC<PurchaseSuccessProps> = ({ style }) => {
  const { tenant_name, map_name, user_id } =
    useParams<SuccessPurchaseRouteParams>();
  const timestamp = new Date().toISOString();

  return (
    <Container
      maxWidth="sm"
      style={{
        textAlign: "center",
        marginTop: "50px",
        marginBottom: "50px",
        ...style,
      }}
    >
      <CheckCircleOutlineIcon
        style={{ fontSize: 60, color: "green", marginBottom: "20px" }}
      />
      <Typography variant="h4" gutterBottom style={{ marginBottom: "24px" }}>
        Purchase Successful!
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "16px" }}>
        Congratulations, you have purchased a Pioneer Map.
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/mymaps"
        style={{
          backgroundColor: "#2A3235",
          color: "#FFFFFF",
          marginTop: "32px",
          padding: "10px 20px",
        }}
      >
        Access Your Maps
      </Button>
      {/* Additional information section for debugging */}
      <Typography
        variant="body2"
        component="span"
        color="text.secondary"
        style={{
          display: "block",
          overflowWrap: "break-word",
          maxWidth: "100%",
          marginTop: "24px",
        }}
      >
        ID: {tenant_name}/{map_name}, User: {user_id}, Time: {timestamp}
      </Typography>
    </Container>
  );
};

export default SuccessPurchase;
