import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MapConfigItem } from "./TenantMapContext";
import { useApiService } from "@visitswitzerland/common";
import { useAlert } from "./AlertContext";

// Assuming this is how you've structured your map item
interface UserProfile {
  name: string;
  email: string;
  mymaps: MapConfigItem[];
}

interface UserContextType {
  profile: UserProfile | null;
  setProfile: (profile: UserProfile | null) => void;
}

const initialUserContextValue: UserContextType = {
  profile: null,
  setProfile: () => {},
};

const UserContext = createContext<UserContextType>(initialUserContextValue);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const apiService = useApiService();
  const { showAlert } = useAlert();

  useEffect(() => {
    // Function to fetch maps and update the profile
    const fetchAndUpdateMaps = async () => {
      try {
        const response = await apiService.getMaps();
        const transformedMaps = response.maps.map((mapItem) => ({
          tenant: mapItem.tenant_name,
          map: mapItem.map_name,
          site: "WIP",
          creator:
            mapItem.tenant_name === "djemographic"
              ? "@djemographic"
              : "@" + mapItem.tenant_name,
          price: "WIP",
          discountedPrice: "WIP",
          priceID: "WIP",
          title:
            mapItem.map_name === "topvillages"
              ? "Top Switzerland Guide"
              : mapItem.map_name,
          description: "WIP",
          imagePath: "WIP",
        }));

        // Update profile with new maps data
        setProfile((currentProfile) => ({
          ...currentProfile,
          mymaps: transformedMaps,
        }));
      } catch (error) {
        console.error("Failed to fetch maps:", error);
        showAlert("Oops! Something went wrong.", "error", 5);
      }
    };

    if (isAuthenticated && !isLoading) {
      const userProfile: UserProfile = {
        name: user.name,
        email: user.email,
        mymaps: [],
      };
      setProfile(userProfile);

      fetchAndUpdateMaps();
    } else {
      setProfile(null);
    }
  }, [user, isAuthenticated, isLoading, apiService]);

  return (
    <UserContext.Provider value={{ profile, setProfile }}>
      {children}
    </UserContext.Provider>
  );
};
