const youtube = {
  name: 'youtube',
  urlVideoBase: 'https://www.youtube.com/watch?v=',
  domains: ['youtube.com', 'youtu.be', 'm.youtube.com'],
  validateVideoID: function(id) {
    const regExp = /^[a-zA-Z0-9-_]{11}$/;
    return regExp.test(id);
  },
  parseURL: function(url) {
    let parsed = {};
    // strip 'www.' if present
    const domain = url.hostname.replace(/^(www\.)/, '');
    const domainIdx = this.domains.indexOf(domain);
    if (domainIdx < 0) {
      return {supported: false};
    }
    parsed = {
      ...parsed,
      platform: 'youtube',
    };

    // detect video id
    let platformVideoID = '';
    if (this.domains[domainIdx] == 'youtu.be') {
      // video id in path: /8BYG8ZXmtiU
      platformVideoID = url.pathname.replace(/^\/+/, '');
    } else {
      platformVideoID = url.searchParams.get('v');
      if (!platformVideoID) {
        return {supported: false};
      }
    }

    if (!this.validateVideoID(platformVideoID)) {
      return {supported: false};
    }

    parsed = {
      ...parsed,
      platformVideoID: platformVideoID,
    };

    // find optional startTime
    // TODO handle strings like '1h5m32s'
    const timeStr = url.searchParams.get('t');
    const time = Number(timeStr);
    if (timeStr && !isNaN(time)) {
      parsed = {
        ...parsed,
        time: time,
      };
    }

    return {supported: true, parsed: parsed};
  },
  videoURL: function(videoID, startTime) {
    let url = this.urlVideoBase + videoID;
    if (!isNaN(startTime)) {
      url = url + '&t=' + startTime;
    }
    return url;
  },

  thumbnailURL: function(videoID) {
    return 'https://img.youtube.com/vi/' + videoID + '/0.jpg';
  },
};

const vimeo = {
  name: 'vimeo',
  urlVideoBase: 'https://player.vimeo.com/video/',
  domains: ['vimeo.com', 'player.vimeo.com'],
  parseTimeString: function(timeStr) {
    let seconds = 0;
    const minStr = timeStr.substring(0, timeStr.indexOf('m'));
    if (minStr !== '') {
      const min = Number(minStr);
      if (isNaN(min)) {
        return {valid: false};
      }
      seconds += 60*min;
    }

    if (timeStr.length - 1 == timeStr.indexOf('m')) {
      return {valid: true, seconds: seconds};
    }

    const secStr = timeStr.substring(
        timeStr.indexOf('m') + 1,
        timeStr.indexOf('s'),
    );
    if (secStr !== '') {
      const sec = Number(secStr);
      if (isNaN(sec)) {
        return {valid: false};
      }
      seconds += sec;
    }

    return {valid: true, seconds: seconds};
  },
  validateVideoID: function(id) {
    const regExp = /^[0-9]+$/;
    return regExp.test(id);
  },
  parseURL: function(url) {
    let parsed = {};
    // strip 'www.' if present
    const domain = url.hostname.replace(/^(www\.)/, '');
    // check if valid platform domain
    if (!(this.domains.includes(domain))) {
      return {supported: false};
    }
    parsed = {
      ...parsed,
      platform: this.name,
    };

    // find video id
    const path = url.pathname;
    const platformVideoID = path.substring(path.lastIndexOf('/') + 1);
    if (!this.validateVideoID(platformVideoID)) {
      return {supported: false};
    }
    parsed = {
      ...parsed,
      platformVideoID: platformVideoID,
    };

    // find optional startTime
    if (url.hash.startsWith('#t=')) {
      const timeStr = url.hash.replace(/^(#t=)/, '');
      const parsedTime = this.parseTimeString(timeStr);
      if (parsedTime.valid) {
        parsed = {
          ...parsed,
          time: parsedTime.seconds,
        };
      }
    }
    return {supported: true, parsed: parsed};
  },
  videoURL: function(videoID, startTime) {
    return this.urlVideoBase + videoID;
    // Adding #t=<startTime> does not help in ReactPlayer with vimeo.
    // But seekTo works in contrast to youtube
  },

  thumbnailURL: function(videoID) {
    return '';
  },
};

export const platforms = {
  youtube: youtube,
  vimeo: vimeo,
};

export const defaultLocation = {
  lat: 46.7985286,
  lng: 8.2317948,
  zoom: 5.7,
};

export function getVideoURL(platform, platformVideoID, startTime) {
  if (platforms[platform]) {
    return platforms[platform].videoURL(platformVideoID, startTime);
  }
  return '';
}

// http://localhost:3001/?p=youtube&v=05f1WZFgmCY&t=450&l=47.3798745,8.4813549,16.45
export function parseURLparams(params) {
  let parsed = {};

  if (!params.p || !platforms[params.p]) {
    return {valid: false};
  }
  parsed = {
    ...parsed,
    platform: params.p,
  };
  // validate platformVideoID
  if (!params.v) {
    return {valid: false};
  }
  // TODO test length. differentiate platform
  const regExp = /^[a-zA-Z0-9-_]+$/;
  if (!regExp.test(params.v)) {
    return {valid: false};
  }
  parsed = {
    ...parsed,
    platformVideoID: params.v,
  };
  // optional params
  const time = Number(params.t);
  if (params.t && !isNaN(time)) {
    parsed = {
      ...parsed,
      time: time,
    };
  } else {
    parsed = {
      ...parsed,
      time: 0,
    };
  }
  const lat = Number(params.lat);
  const lng = Number(params.lng);
  const z = Number(params.z);
  if (!isNaN(lat) && params.lat >= -90 && params.lat <= 90) {
    if (!isNaN(lng) && params.lng >= 0 && params.lng <= 180) {
      if (!isNaN(z) && params.z >= 0 && params.z <= 24) {
        parsed = {
          ...parsed,
          location: {
            lat: lat,
            lng: lng,
            zoom: z,
          },
        };
      }
    }
  }
  return {valid: true, parsed: parsed};
}

export function validURL(input) {
  const regExp = new RegExp(['^(http(s)?):\\/\\/(www\\.)?[a-zA-Z0-9@:%._\\+',
    '~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$'].join(''));
  if (regExp.test(input)) {
    return true;
  }
  return false;
}

export function parseURL(input) {
  try {
    const url = new URL(input);
    // for each platform try to parse
    const platformsArr = Object.values(platforms);
    for (let i = 0; i < platformsArr.length; i++) {
      const parsed = platformsArr[i].parseURL(url);
      if (parsed.supported) {
        return parsed;
      }
    }
  } catch (error) {
    return {supported: false};
  }
  return {supported: false};
}
