// OfferMap.tsx
import * as React from "react";
import {
  Typography,
  Button,
  Stack,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "./AlertContext";
import {
  useApiService,
  CreateCheckoutSessionResponse,
} from "@visitswitzerland/common";
import { sendEvent } from "../analytics/events";
import useHandleAuthAction from "../useHandleAuthAction";

export interface OfferMapProps {
  title: string;
  creator: string;
  tenantName: string;
  mapName: string;
  priceID: string;
  originalPrice: string;
  discountedPrice: string;
  description: string;
  imagePath: string;
}

export function OfferMap({
  title,
  creator,
  tenantName,
  mapName,
  priceID,
  originalPrice,
  discountedPrice,
  description,
  imagePath,
}: OfferMapProps) {
  const { isAuthenticated, user } = useAuth0();
  const apiService = useApiService();
  const { showAlert } = useAlert();
  const handleAuthAction = useHandleAuthAction();

  const handleAuth = (action: "login" | "signup") => {
    handleAuthAction({ action, tenantName, mapName }).catch((err) => {
      console.error(`Error during ${action}`, err);
      // Optionally handle the error, show an alert if needed
    });
  };

  const handleBuyNow = () => {
    sendEvent("User", "Click Buy Now", `${tenantName} - ${mapName}`);

    apiService
      .createCheckoutSession(
        priceID,
        tenantName,
        mapName,
        user.email,
        user.name
      )
      .then((response: CreateCheckoutSessionResponse) => {
        // Assuming the response is properly typed, accessing the URL from the response data
        const checkoutUrl = response.url;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Redirect the user to Stripe Checkout
        } else {
          console.error("Checkout URL not found in the response.");
          showAlert("Oops! Something went wrong.", "error", 5);
        }
      })
      .catch((error) => {
        console.error("Error creating checkout session:", error);
        showAlert("Oops! Something went wrong.", "error", 5);
      });
  };

  return (
    <Card sx={{ maxWidth: 345, mx: "auto" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle2"
          component="div"
          color="text.secondary"
          textAlign="center"
        >
          {creator}
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          textAlign="center"
        >
          {title}
        </Typography>
        {isAuthenticated && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textDecoration: "line-through" }}
            >
              {originalPrice}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#2A3235" }}
            >
              {discountedPrice}
            </Typography>
          </Stack>
        )}
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          fullWidth
          sx={{
            bgcolor: "#2A3235",
            mb: 1,
            textTransform: "none",
            "&:hover": {
              bgcolor: "#2A3235",
            },
          }}
          onClick={isAuthenticated ? handleBuyNow : () => handleAuth("signup")}
        >
          {isAuthenticated ? "Buy Now" : "Sign up"}
        </Button>
        {!isAuthenticated && (
          <Button
            size="small"
            variant="text"
            fullWidth
            sx={{
              textTransform: "none",
            }}
            onClick={() => handleAuth("login")}
          >
            Login
          </Button>
        )}
      </CardActions>
      <CardMedia
        component="img"
        sx={{
          width: 200,
          height: "auto",
          objectFit: "contain",
          mx: "auto",
          mb: 2,
        }} // Added bottom margin (mb)
        image={imagePath}
        alt={title}
      />
    </Card>
  );
}
