// LabelSeason.tsx
import React from "react";
import { Label, LabelProps } from "./Label";

export interface LabelSeasonProps extends Omit<LabelProps, "icon"> {
  // You can add any additional props specific to LabelSeason here
}

export const LabelSeason: React.FC<LabelSeasonProps> = (props) => {
  // Inline SVG icon
  const CalendarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.5 -0.5 16 16"
      fill="none"
      stroke="currentColor" // Use currentColor to inherit text color
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* SVG paths */}
      <path
        d="M7.5 13.125H3.75a1.25 1.25 0 0 1 -1.25 -1.25V4.375a1.25 1.25 0 0 1 1.25 -1.25h7.5a1.25 1.25 0 0 1 1.25 1.25v3.125"
        strokeWidth="1"
      />
      <path d="M10 1.875v2.5" strokeWidth="1" />
      <path d="M5 1.875v2.5" strokeWidth="1" />
      <path d="M2.5 6.875h10" strokeWidth="1" />
      <path
        d="M10.6256 11.875a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0 -2.5 0"
        strokeWidth="1"
      />
      <path d="M11.8756 9.6875V10.625" strokeWidth="1" />
      <path d="M11.8756 13.125v0.9375" strokeWidth="1" />
      <path d="m13.77 10.7813 -0.8119 0.4688" strokeWidth="1" />
      <path d="m10.7938 12.5 -0.8125 0.4688" strokeWidth="1" />
      <path d="m9.98125 10.7813 0.8125 0.4688" strokeWidth="1" />
      <path d="m12.9581 12.5 0.8125 0.4688" strokeWidth="1" />
    </svg>
  );

  return <Label icon={CalendarIcon} iconSize={32} {...props} />;
};
