import React, { useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Define the props interface
export interface CategorySelectProps {
  name: string;
  categories: { name: string; count: number }[];
  selectedCategories: string[];
  onSelectionChange: (selectedCategories: string[]) => void;
}

export const CategorySelect: React.FC<CategorySelectProps> = ({
  name,
  categories,
  selectedCategories,
  onSelectionChange,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleDelete = (categoryToDelete: string) => {
    onSelectionChange(
      selectedCategories.filter((category) => category !== categoryToDelete)
    );
  };

  const handleChange = (
    event: SelectChangeEvent<typeof selectedCategories>
  ) => {
    const value = event.target.value;
    onSelectionChange(typeof value === "string" ? value.split(",") : value);
  };

  // Sort categories alphabetically by name
  const sortedCategories = categories.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Vertical on small screens, horizontal on others
          flexWrap: "wrap",
          gap: 0.5,
          marginBottom: 2,
          maxWidth: "100%", // Ensure the Box container does not exceed its parent width
        }}
      >
        {selectedCategories.map((category) => (
          <Chip
            key={category}
            label={category}
            onDelete={() => handleDelete(category)}
            deleteIcon={<CloseIcon />}
            sx={{
              maxWidth: "100%", // Ensure the Chip does not exceed the Box width
              ".MuiChip-label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }, // Truncate long text
            }}
          />
        ))}
      </Box>
      <FormControl fullWidth>
        <InputLabel>{name}</InputLabel>
        <Select
          multiple
          value={selectedCategories}
          onChange={handleChange}
          input={<OutlinedInput label="Select Categories" />}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderValue={() => "Select more..."}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250, // Set maximum height for the dropdown list
                overflow: "auto", // Enable scrolling for overflow content
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            anchorPosition: { left: 0, top: 0 },
          }}
        >
          {sortedCategories.map(({ name, count }) => (
            <MenuItem
              key={name}
              value={name}
              style={{
                fontWeight: selectedCategories.includes(name) ? 600 : 400,
              }}
            >
              {`${name} (${count})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
