export function videoLocalizerURL(
  platform: string,
  platformVideoID: string,
  timeStart: number
) {
  let videoLocalizerURLParams: { p: string; v: string; t?: string } = {
    p: platform,
    v: platformVideoID,
  };
  if (timeStart) {
    videoLocalizerURLParams = {
      ...videoLocalizerURLParams,
      t: timeStart.toString(),
    };
  }
  return {
    search: new URLSearchParams(videoLocalizerURLParams).toString(),
    pathname: "/v",
  };
}

// SplitWords("hello dude what are you doing", " ", 3)
// return ["hello dude what", " are you doing"]
export function SplitWords(
  str: string,
  subString: string,
  nth: number
): string[] {
  if (!str || str.length === 0) {
    return [];
  }
  let index = str.split(subString, nth).join(subString).length;
  const result = [str.slice(0, index), str.slice(index)];

  return result;
}
