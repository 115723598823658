import React, { useEffect } from "react";

export interface GetYourGuideWidgetProps {
  partnerID: string; // e.g. "EQU1PHQ"
  widgetType?: string; // "activities" or "city"
  type: string; // "activities" or "city" or "auto"
  autoTags?: string;

  // Same as IGetGYGResponse
  locationID?: number;
  query?: string | undefined;
  tourIDs?: number[] | undefined;
  numberOfItems?: number | undefined;
  excludedTourIDs?: number[] | undefined;
  coordinate?:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  cityWidget?: boolean | undefined;
}

// https://partner.getyourguide.com/en-us/solutions/activities
export const GetYourGuideWidget: React.FC<GetYourGuideWidgetProps> = ({
  partnerID,
  widgetType,
  type,
  autoTags,
  tourIDs,
  numberOfItems,
  query,
  locationID,
  excludedTourIDs,
  coordinate,
  cityWidget,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.getyourguide.com/dist/pa.umd.production.min.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }, []);

  let attributes = {
    "data-gyg-widget": type,
    "data-gyg-partner-id": partnerID,
  };

  if (type === "auto") {
    return (
      <div {...attributes}>
        <div hidden={true}>{autoTags}</div>
      </div>
    );
  }

  // set numberOfItems to a default value if not set by API call
  let numItems = 2;
  if (numberOfItems) {
    numItems = numberOfItems;
  }

  attributes = {
    ...attributes,
    // @ts-ignore
    "data-gyg-href":
      widgetType === "activities"
        ? "https://widget.getyourguide.com/default/activities.frame"
        : "https://widget.getyourguide.com/default/city.frame",
    "data-gyg-locale-code": "en-US",
    // data-gyg-number-of-items is ignored when widgetType = city
    "data-gyg-number-of-items": numItems,
  };

  if (locationID || locationID === 0) {
    attributes = {
      ...attributes,
      // @ts-ignore
      "data-gyg-location-id": locationID,
    };
  }

  // if tourIDs.length != numberOfItems the first
  // numberOfItems are displayed
  // is ignored when locationID is defined
  // @ts-ignore
  if (tourIDs && tourIDs?.length > 0) {
    let num = 1;
    if (tourIDs.length >= 2) {
      num = 2;
    }
    attributes = {
      ...attributes,
      // @ts-ignore
      "data-gyg-tour-ids": tourIDs.join(","),
      "data-gyg-number-of-items": num,
    };
  }

  // ignored when widgetType = city
  // ignored when locationID defined
  if (query) {
    attributes = {
      ...attributes,
      // @ts-ignore
      "data-gyg-q": query,
    };
  }

  // e.g. data-gyg-excluded-tour-ids="4875,292285”
  // ignored when widgetType = city
  // @ts-ignore
  if (excludedTourIDs && excludedTourIDs?.length > 0) {
    attributes = {
      ...attributes,
      // @ts-ignore
      "data-gyg-excluded-tour-ids": excludedTourIDs.join(","),
    };
  }

  // ignored when widgetType = city
  // ignored when locationID or query defined
  if (coordinate) {
    attributes = {
      ...attributes,
      // @ts-ignore
      "data-gyg-lat": coordinate.lat,
      // @ts-ignore
      "data-gyg-lon": coordinate.lng,
    };
  }

  return <div {...attributes} />;
};
