import React from "react";

const divStyle = {
  marginTop: 92,
  marginLeft: 40,
  marginRight: 40,
};

export const TermsAndConditionsPage: React.FC = () => {
  return (
    <div style={divStyle}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        body, h1, h2, h3, p, li, a { margin: 0; padding: 0; }
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font-family: Arial !important;
            font-size: 26px !important;
            color: #000000 !important;
            text-transform: uppercase;
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font-family: Arial !important;
            color: #595959 !important;
            font-size: 16px !important; /* Adjusted for visibility */
            text-transform: none; /* No uppercase for subtitle */
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font-family: Arial !important;
            font-size: 22px !important;
            color: #000000 !important;
            text-transform: uppercase;
            margin-top: 24px; /* Added spacing above headings for better separation */
            margin-bottom: 12px; /* Added spacing below headings */
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
            font-size: 14px !important;
            font-family: Arial !important;
            line-height: 1.5; /* Improved line spacing for readability */
            margin-bottom: 16px; /* Added spacing between paragraphs for better separation */
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
            font-size: 14px !important;
            font-family: Arial !important;
            word-break: break-word !important;
            text-decoration: underline; /* Make it clear that table of contents items are links */
        }
        ul.table-of-contents {
            list-style-type: none;
            padding: 0;
            margin-bottom: 24px; /* Added spacing below the table of contents */
        }
        ul.table-of-contents li {
            font-size: 14px;
            margin-bottom: 10px;
        }
      `,
        }}
      />
      <div data-custom-class="body">
        <div>
          <strong>
            <span style={{ fontSize: "26px" }}>
              <span data-custom-class="title">
                GENERAL TERMS AND CONDITIONS (GTC)
              </span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span style={{ fontSize: "16px" }}>
            <span data-custom-class="subtitle">
              Last updated March 30, 2024
            </span>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span data-custom-class="body_text">
            <div id="toc">
              <strong>
                <span
                  style={{ fontSize: "22px" }}
                  data-custom-class="heading_1"
                >
                  TABLE OF CONTENTS
                </span>
              </strong>
              <ul className="table-of-contents">
                <li>
                  <a href="#subject" data-custom-class="link">
                    SUBJECT OF THE CONTRACT
                  </a>
                </li>
                <li>
                  <a href="#rights" data-custom-class="link">
                    RIGHTS OF USE
                  </a>
                </li>
                <li>
                  <a href="#availability" data-custom-class="link">
                    AVAILABILITY
                  </a>
                </li>
                <li>
                  <a href="#pricing" data-custom-class="link">
                    PRICES AND PAYMENT
                  </a>
                </li>
                <li>
                  <a href="#withdrawal" data-custom-class="link">
                    RIGHT OF WITHDRAWAL
                  </a>
                </li>
                <li>
                  <a href="#liability" data-custom-class="link">
                    LIABILITY
                  </a>
                </li>
                <li>
                  <a href="#changes" data-custom-class="link">
                    CHANGES TO THE GTC
                  </a>
                </li>
                <li>
                  <a href="#final" data-custom-class="link">
                    FINAL PROVISIONS
                  </a>
                </li>
              </ul>
            </div>
            <p>
              These General Terms and Conditions (hereinafter referred to as
              &quot;GTC&quot;) apply to all purchases of digital maps made by
              users (hereinafter referred to as &quot;Customer&quot; or
              &quot;You&quot;) via the online platform PioneerMaps (hereinafter
              referred to as &quot;Platform&quot;). The platform is operated by
              Christof Gerber (hereinafter referred to as &quot;we&quot; or
              &quot;us&quot;).
            </p>
            <h2 id="subject" data-custom-class="heading_1">
              SUBJECT OF THE CONTRACT
            </h2>
            <p>
              These GTC govern the purchase and use of digital maps offered on
              our platform. Digital maps are created and distributed by
              third-party providers (hereinafter referred to as
              &quot;Provider&quot;).
            </p>
            <h2 id="rights" data-custom-class="heading_1">
              RIGHTS OF USE
            </h2>
            <p>
              With the purchase of a digital map, you acquire the non-exclusive,
              non-transferable right to use the map for your personal use in
              accordance with these GTC. The ownership and usage rights of the
              digital maps remain with their respective rights holders.
            </p>
            <h2 id="availability" data-custom-class="heading_1">
              AVAILABILITY
            </h2>
            <p>
              The availability of digital maps depends on the provider. We do
              not guarantee that a once-purchased digital map will remain
              available permanently. Should a provider decide to remove their
              map from the platform, it will no longer be accessible to you. In
              such cases, there is no entitlement to a refund or compensation.
            </p>
            <h2 id="pricing" data-custom-class="heading_1">
              PRICES AND PAYMENT
            </h2>
            <p>
              The prices for digital maps on the platform may be indicated in
              various currencies and include all applicable taxes and fees. The
              exact currency will be displayed during the purchase process.
              Payment is made via the payment methods provided by the platform.
              Please note that transaction fees may vary depending on the
              payment processing platform used and the chosen currency of sale.
            </p>
            <h2 id="withdrawal" data-custom-class="heading_1">
              RIGHT OF WITHDRAWAL
            </h2>
            <p>
              Digital content not delivered on a physical medium is excluded
              from the right of withdrawal as soon as the download or the use of
              the digital map has begun.
            </p>
            <h2 id="liability" data-custom-class="heading_1">
              LIABILITY
            </h2>
            <p>
              The platform is not liable for the accuracy, completeness, or
              timeliness of the digital maps. Liability for direct or indirect
              damages resulting from the use or non-usability of the digital
              maps is excluded to the extent permitted by law.
            </p>
            <h2 id="changes" data-custom-class="heading_1">
              CHANGES TO THE GTC
            </h2>
            <p>
              We reserve the right to change these GTC at any time. The current
              version of the GTC can be viewed on the platform.
            </p>
            <h2 id="final" data-custom-class="heading_1">
              FINAL PROVISIONS
            </h2>
            <p>
              Should individual provisions of these GTC become ineffective or
              void, the validity of the remaining provisions shall remain
              unaffected. In place of the ineffective provision, a regulation
              that comes closest to the original purpose shall apply.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};
