import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import API from "./services";

const ApiServiceContext = createContext(null);

export const ApiServiceProvider = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [apiService, setApiService] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        return token;
      } catch (error) {
        console.error("Error during getAccessTokenSilently", error);
        localStorage.setItem(
          "postLogoutRedirectPath",
          window.location.pathname
        );
        logout({
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
          logoutParams: {
            returnTo: window.location.origin + "/auth-callback",
          },
        }).catch((err) => {
          console.error("Error during logout", err);
        });
        return null;
      }
    };

    const api = new API(getToken);
    setApiService(api);
  }, [getAccessTokenSilently]);

  return (
    <ApiServiceContext.Provider value={apiService}>
      {children}
    </ApiServiceContext.Provider>
  );
};

export const useApiService = () => useContext(ApiServiceContext);
