import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MapVisitSwitzerland from "./components/MapVisitSwitzerland";
import {
  InstaPostThumbnailProp,
  InstaPostThumbnail,
} from "./components/InstaPostThumbnail";
import { Video } from "@visitswitzerland/common";
import { styled } from "@mui/material/styles";
import { Global, css } from "@emotion/react";
import { SwipeableEdgeDrawer } from "./components/SwipeableEdgeDrawer";
import { GetYourGuideWidgetProps } from "./components/GetYourGuideWidget";
import { useAuth0 } from "@auth0/auth0-react";
import { TikTokEmbed } from "./components/TikTokEmbed";

const drawerBleeding = 52;

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  justifyContent: "center",
}));

export interface OverviewMapPageProps {
  mapStyle: string;
  mapboxApiAccessToken: string;
  gygPartnerID: string;
}

export interface IMarkerState {
  lat: number;
  lng: number;
  type: string;
  thumbnailURL?: string;
  id: string;
  startTime?: number;
  userPhoto?: string;
  userPost?: string;
  address?: string;
  text?: string;
  localInfoURL?: string;
  categories?: string[];
  destination?: string;
}

export const OverviewMapPage: React.FC<OverviewMapPageProps> = ({
  mapStyle,
  mapboxApiAccessToken,
  gygPartnerID,
}) => {
  const [drawerActive, setDrawerActive] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [marker, setMarker] = useState<IMarkerState | undefined>(undefined);
  const [displayActivity, setDisplayActivity] = useState(false);
  const [localInfoURL, setLocalInfoURL] = useState<string | undefined>(
    undefined
  );
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [text, setText] = useState<string | undefined>(undefined);
  const [categories, setCategories] = useState<string[] | undefined>([]);
  const [destination, setDestination] = useState<string | undefined>("");

  const [gygWidget, setGYGwidget] = useState<GetYourGuideWidgetProps>({
    partnerID: gygPartnerID,
    type: "auto",
    autoTags: destination,
  });

  const [location, setLocation] = useState<
    { longitude: number; latitude: number } | undefined
  >(undefined);

  const [playedSeconds, setPlayedSeconds] = React.useState<number>(0);

  useEffect(() => {
    if (marker === undefined) {
      return;
    }
    setLocation({ longitude: marker.lng, latitude: marker.lat });
    setDisplayActivity(false);
    setLocalInfoURL(marker.localInfoURL);
    setAddress(marker.address);
    setText(marker.text);
    setCategories(marker.categories);
    setDestination(marker.destination);
    setGYGwidget({
      partnerID: gygPartnerID,
      type: "auto",
      autoTags: destination,
    });
  }, [marker, gygPartnerID, destination]);

  const renderMediaComponent = () => {
    if (marker) {
      switch (marker.type) {
        case "instagram":
          return (
            <InstaPostThumbnail
              thumbnailURL={marker.thumbnailURL}
              instagramPostID={marker.id}
              userPhoto={marker.userPhoto}
              userPost={marker.userPost}
              embedPost={true}
            />
          );
        case "youtube":
        case "vimeo":
          return (
            <Video
              startTime={marker.startTime}
              platform={marker.type}
              platformVideoID={marker.id}
              drawerOpen={drawerOpen}
              updatePlaytime={setPlayedSeconds}
            />
          );
        case "tiktok":
          return (
            <TikTokEmbed
              url={"https://www.tiktok.com/" + decodeURIComponent(marker.id)}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Root>
      <Global
        styles={
          marker?.type === "youtube" || marker?.type === "vimeo"
            ? css`
                .MuiDrawer-root > .MuiPaper-root {
                  height: 572px;
                  overflow: visible;
                }
                @media (min-width: 992px) and (max-width: 1600px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 522px;
                    overflow: visible;
                  }
                }
                @media (min-width: 768px) and (max-width: 991px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 472px;
                    overflow: visible;
                  }
                }
                @media (min-width: 576px) and (max-width: 767px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 472px;
                    overflow: visible;
                  }
                }
                @media (max-width: 575px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 332px;
                    overflow: visible;
                  }
                }
              `
            : css`
                .MuiDrawer-root > .MuiPaper-root {
                  height: 524px;
                  overflow: visible;
                }
                @media (max-height: 667px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 424px;
                    overflow: visible;
                  }
                }
                @media (min-height: 1024px) {
                  .MuiDrawer-root > .MuiPaper-root {
                    height: 894px;
                    overflow: visible;
                  }
                }
              `
        }
      />
      <Box height={"100vh"}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
          height={"100%"}
          width={"100%"}
          mx={"auto"}
          pt={0.7}
          borderRadius={32}
        >
          <MapVisitSwitzerland
            mapStyle={mapStyle}
            mapboxApiAccessToken={mapboxApiAccessToken}
            setDrawerActive={setDrawerActive}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            marker={marker}
            setMarker={setMarker}
            playedSeconds={playedSeconds}
          />
        </Box>
      </Box>
      {drawerActive && (
        <SwipeableEdgeDrawer
          media={renderMediaComponent()}
          drawerBleeding={drawerBleeding}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          gygWidget={gygWidget}
          displayActivity={displayActivity}
          setDisplayActivity={setDisplayActivity}
          localInfoURL={localInfoURL}
          address={address}
          text={text}
          categories={categories}
          destination={destination}
          location={location}
          setPlayedSeconds={setPlayedSeconds}
        />
      )}
    </Root>
  );
};
