import React, { createContext, ReactNode, useContext, useState } from "react";

// Define the shape of the alert state
interface AlertState {
  isOpen: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
  duration: number;
}

// Define the shape of the context's value
interface AlertContextProps {
  alertState: AlertState;
  showAlert: (
    message: string,
    severity: "error" | "warning" | "info" | "success",
    duration: number
  ) => void;
  closeAlert: () => void;
}

// Create the context
const AlertContext = createContext<AlertContextProps | undefined>(undefined);

// Custom hook to use the alert context
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

// Provider component
export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alertState, setAlertState] = useState<AlertState>({
    isOpen: false,
    message: "",
    severity: "info",
    duration: 0,
  });

  const showAlert = (
    message: string,
    severity: "error" | "warning" | "info" | "success" = "info",
    duration: number = 0
  ) => {
    setAlertState({ isOpen: true, message, severity, duration });
  };

  const closeAlert = () => {
    setAlertState({ ...alertState, isOpen: false });
  };

  return (
    <AlertContext.Provider value={{ alertState, showAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
