import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A3235",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#6B7A8F",
      contrastText: "#ffffff",
    },
    error: {
      main: "#D32F2F",
    },
    warning: {
      main: "#FFA000",
    },
    info: {
      main: "#0288D1",
    },
    success: {
      main: "#388E3C",
    },
    background: {
      default: "#F4F6F8",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#2A3235",
      secondary: "#6B7A8F",
    },
  },
});

export default theme;
