import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const ErrorPage: React.FC = () => {
  // Dynamically get the current URL
  const currentUrl = window.location.href;

  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px" }}
    >
      <Typography
        variant="h1"
        component="h2"
        gutterBottom
        style={{ marginBottom: "32px" }}
      >
        Oops!
      </Typography>
      <Typography variant="h4" gutterBottom style={{ marginBottom: "24px" }}>
        404 Not Found
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "16px" }}>
        Sorry, an error has occurred. The requested page cannot be found!
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "20px" }}>
        If you need assistance, please contact us at{" "}
        <a
          href="mailto:support@pioneermaps.com"
          style={{ textDecoration: "none" }}
        >
          support@pioneermaps.com
        </a>
        .
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "20px" }}>
        Please share the URL:
        <Typography
          variant="body2"
          component="span"
          color="text.secondary"
          style={{
            display: "block", // Changed to block for better control of spacing
            overflowWrap: "break-word",
            maxWidth: "100%",
            marginBottom: "24px", // Add space before the Reference ID
          }}
        >
          {currentUrl}
        </Typography>
        along with Reference ID: PM-404-ERROR.
      </Typography>
      <Button
        variant="contained"
        startIcon={<MailOutlineIcon />}
        href={`mailto:support@pioneermaps.com?subject=404 Error Page Assistance&body=I encountered a 404 error at the following URL: %0A%0A${encodeURIComponent(
          currentUrl
        )}%0A%0APlease assist.`}
        style={{
          backgroundColor: "#2A3235",
          color: "#FFFFFF",
          marginTop: "32px", // Add space above the button
          padding: "10px 20px", // Increase padding for larger button area
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#1e2729",
          },
        }}
      >
        Contact Support
      </Button>
    </Container>
  );
};

export default ErrorPage;
