import React from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./FilterButtonStyles.css";

export interface FilterButtonProps {
  selectCount: number;
  onClick: () => void;
  disabled?: boolean;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  selectCount,
  onClick,
  disabled = false,
}) => {
  return (
    <div className={`circle ${disabled ? "disabledIcon" : ""}`}>
      <IconButton onClick={onClick} className="svgIcon" disabled={disabled}>
        <Badge
          badgeContent={selectCount}
          overlap="circular"
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <FilterListIcon
            color="primary"
            style={{
              height: "35px",
              width: "35px",
            }}
          />
        </Badge>
      </IconButton>
    </div>
  );
};
