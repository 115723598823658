// LabelPlatform.tsx
import React from "react";
import { Label, LabelProps } from "./Label";

export interface LabelPlatformProps extends Omit<LabelProps, "icon"> {
  // You can add any additional props specific to LabelPlatform here
}

export const LabelPlatform: React.FC<LabelPlatformProps> = (props) => {
  // Inline SVG icon
  const PhotoVideoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.5 -0.5 16 16"
      fill="none"
      stroke="currentColor" // Changed from "#000000" to "currentColor"
      strokeLinecap="round" // Corrected attribute name
      strokeLinejoin="round" // Corrected attribute name
    >
      <desc>Photo Video Streamline Icon: https://streamlinehq.com</desc>
      <path
        d="M5.625 9.375H3.75a1.875 1.875 0 0 1 -1.875 -1.875V3.75a1.875 1.875 0 0 1 1.875 -1.875h3.75a1.875 1.875 0 0 1 1.875 1.875v1.875"
        strokeWidth="1" // Corrected attribute name
      ></path>
      <path
        d="M5.625 7.5a1.875 1.875 0 0 1 1.875 -1.875h3.75a1.875 1.875 0 0 1 1.875 1.875v3.75a1.875 1.875 0 0 1 -1.875 1.875h-3.75a1.875 1.875 0 0 1 -1.875 -1.875z"
        strokeWidth="1" // Corrected attribute name
      ></path>
      <path
        d="m1.875 7.5 1.435 -1.435a1.50625 1.50625 0 0 1 2.13 0L5.625 6.25"
        strokeWidth="1" // Corrected attribute name
      ></path>
      <path d="M8.75 8.4375v1.875l1.5625 -0.9375z" strokeWidth="1"></path>
      <path d="M4.375 3.75v0.00625" strokeWidth="1"></path>
    </svg>
  );

  return <Label icon={PhotoVideoIcon} iconSize={32} {...props} />;
};
