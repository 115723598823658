import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./Header.css";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import history from "../redux/history";
import { ContactForm } from "./ContactForm";
import { Info } from "./Info";
import { Profile } from "./Profile";
import { sendEvent } from "../analytics/events";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "./AlertContext";
import { useTenantMap } from "./TenantMapContext";
import useHandleAuthAction from "../useHandleAuthAction";

export interface HeaderProps {
  logoClickURL: string;
  contactFormURL: string;
}

export const Header: React.FC<HeaderProps> = ({
  logoClickURL,
  contactFormURL,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [contactFormOpen, setContactFormOpen] = React.useState<boolean>(false);
  const [infoOpen, setInfoOpen] = React.useState<boolean>(false);
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { tenantConfig } = useTenantMap();
  const handleAuthAction = useHandleAuthAction();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    console.log(e);
    setAnchorEl(null);
  };

  const handleInfoClick = () => {
    sendEvent("User", "Click Info");
    setAnchorEl(null);
    setInfoOpen(true);
  };

  const handlePrivacyPolicyClick = () => {
    sendEvent("User", "Click Privacy Policy");
    setAnchorEl(null);
    history.push({
      pathname: "/privacy",
    });
  };

  const handleTermsAndConditionsClick = () => {
    sendEvent("User", "Click Terms and Conditions");
    setAnchorEl(null);
    history.push({
      pathname: "/terms",
    });
  };

  const handleMyMapsClick = () => {
    sendEvent("User", "Click My Maps");
    setAnchorEl(null);
    history.push({
      pathname: "/mymaps",
    });
  };

  const handleProfileClick = () => {
    sendEvent("User", "Click Profile");
    setAnchorEl(null);
    setProfileOpen(true);
  };

  const handleContactClick = () => {
    sendEvent("User", "Click Contact");
    setAnchorEl(null);
    setContactFormOpen(true);
  };

  const handleLogIn = () => {
    setAnchorEl(null);
    handleAuthAction({ action: "login" }).catch((err) => {
      console.error("Error during login", err);
      showAlert("Oops! Something went wrong.", "error", 5);
    });
  };

  const { showAlert } = useAlert();

  return (
    <AppBar style={{ background: "#ffffff", zIndex: 1600 }}>
      <Toolbar style={{ justifyContent: "space-between", width: "100%" }}>
        <Box display="flex" alignItems="center">
          <Link
            href={logoClickURL}
            onClick={() => sendEvent("User", "Click Logo")}
          >
            <img
              src={"/pioneermaps_logo.svg"}
              alt="Pioneermaps Logo"
              className="logo"
            />
          </Link>
        </Box>

        <Box display="flex" flexGrow={1} justifyContent="center">
          <div className="header-subtitle">
            {isAuthenticated && tenantConfig?.creator && tenantConfig?.title && (
              <>
                <div className="tenant-name">{tenantConfig.creator}</div>
                <div className="map-name">{tenantConfig.title}</div>
              </>
            )}
          </div>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <IconButton
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ marginRight: 22 }} // Adjust this value as needed
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ zIndex: 1700 }}
      >
        {isAuthenticated && (
          <MenuItem onClick={handleMyMapsClick}>My Maps</MenuItem>
        )}
        {!isAuthenticated && <MenuItem onClick={handleLogIn}>Login</MenuItem>}
        {isAuthenticated && (
          <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
        )}
        <MenuItem onClick={handleInfoClick}>About</MenuItem>
        <MenuItem onClick={handleContactClick}>Contact</MenuItem>
        <MenuItem onClick={handlePrivacyPolicyClick}>Privacy Policy</MenuItem>
        <MenuItem onClick={handleTermsAndConditionsClick}>GTC</MenuItem>
      </Menu>
      <ContactForm
        contactFormOpen={contactFormOpen}
        setContactFormOpen={setContactFormOpen}
        contactFormURL={contactFormURL}
      />
      <Info infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
      <Profile profileOpen={profileOpen} setProfileOpen={setProfileOpen} />
    </AppBar>
  );
};
